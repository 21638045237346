// Styles for User pages
// -----------------------------------------------------------------------------

// notifications hacks
.page-user-notifications-subscription th:nth-child(1),
.page-user-notifications-subscription th:nth-child(2),
.page-user-notifications-subscription th:nth-child(3),
.page-user-notifications-subscription th:nth-child(4),
.page-user-notifications-subscription th:nth-child(6),
.page-user-notifications-subscription th:nth-child(7),
.page-user-notifications-subscription th:nth-child(8),
.page-user-notifications-subscription td:nth-child(1),
.page-user-notifications-subscription td:nth-child(2),
.page-user-notifications-subscription td:nth-child(3),
.page-user-notifications-subscription td:nth-child(4),
.page-user-notifications-subscription td:nth-child(6),
.page-user-notifications-subscription td:nth-child(7),
.page-user-notifications-subscription td:nth-child(8),
.page-user-notifications-subscription .nav-pills.secondary {
  display: none;
}