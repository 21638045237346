// Global styles
// -----------------------------------------------------------------------------
body {
  background-color: #ccc;
  color: #000;
}
img {
  width: 100%;
  height: auto;
}
.alert img {
  width: auto;
}
iframe {
  max-width: 100%;
}
.label {
    font-weight: bold;
    margin-bottom: 5px;
  }

.btn + .btn { margin-left: 0.5em; }

th img { width: auto; }

.container {
  @media (min-width: 720px) {
    width: 100%;
    max-width: 1024px;
  }
  @media (min-width: 1024px) {
    width: 100%;
    max-width: 1440px;
  }
  @media (min-width: 1440px) {
    width: 100%;
    max-width: 1600px;
  }
}
