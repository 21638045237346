// Typography
// -----------------------------------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  letter-spacing: -.05em;
}

.red { text-color: $brand-danger; }

// override badly pasted text
li span,
li em,
p span,
p em {
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}
// hide embedded iframes in favor of youtube field embeds
//.node iframe { display: none; }
//.node .field-name-field-video iframe { display: block; }