// Styles for Views
// -----------------------------------------------------------------------------
.view-tradeknives .views-field-title a,
.view-search .views-field-title a,
.taxonomy-for-sale .views-field-title a,
.taxonomy-sold .views-field-title a,
.view-makers a {
  color: #000;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.page-home .view-tradeknives .views-row,
.page-discount .views-row,
.page-tradeknives .views-row,
.taxonomy-sold .views-row,
.view-makers .views-row,
.view-search .views-row {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1em;
  & img {
    margin-bottom: .5em;
  }
  & .views-field-name {
    margin-bottom: 1em;
  }
}

// set views images to position rel so we can better control the flag position
//.view-tradeknives .views-field-field-image,
//.taxonomy-for-sale .views-field-field-image,
//.taxonomy-sold .views-field-field-image {
//  position: relative;
//}

// @todo fix this via views tpl
// this hides the representative taxonomy image in favor of the taxonomy image field
.view-makers img:nth-child(2) { display: none; }

@media (min-width:860px) and (max-width: 1440px) {
  .page-home .view-tradeknives .views-row,
  .page-discount .views-row,
  .page-tradeknives .views-row,
  .taxonomy-sold .views-row,
  .view-makers .views-row,
  .view-search .views-row {
    float: left;
    margin-left: 1.45%;
    margin-right: 1.45%;
    width: 48.5%;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(1),
    &:nth-child(2n+1) {
      margin-left: 0;
      clear:both;
    }
  }
}

@media (min-width: 1441px) {
  .page-home .view-tradeknives .views-row,
  .page-discount .views-row,
  .page-tradeknives .views-row,
  .taxonomy-sold .views-row,
  .view-makers .views-row,
  .view-search .views-row {
    float: left;
    margin-left: .95%;
    margin-right: .95%;
    width: 32%;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(1),
    &:nth-child(3n+1) {
      clear:both;
      margin-left: 0;
    }
  }
}


.taxonomy-for-sale .views-row {
  clear: both;
  margin-top: 1em;
  position: relative;
}
@media (min-width: 860px) {
  .taxonomy-for-sale .views-field-field-image {
//    float: left;
//    padding-right: 1.5em;
//    width: 33%;
//    box-sizing: border-box;
//  }
    float: left;
    margin-left: .95%;
    margin-right: .95%;
    width: 32%;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(1),
    &:nth-child(3n+1) {
      clear:both;
      margin-left: 0;
    }
  }
}
.taxonomy-for-sale,
.taxonomy-sold {
  clear: both;
}
.taxonomy-sold .views-field-field-knife-promotext p { margin-bottom: 0; }

.views-exposed-form .views-exposed-widget {
  margin-bottom: 0;
  float: none;
  padding: 0;
  &.views-widget-filter-combine {
    width: 100%;
  }
  & input[type='submit'] {
    margin: .5em 0;
  }
}


@media (min-width:690px) {
  .views-exposed-form .views-exposed-widget {
    margin-bottom: 1em;
    float: left;
    padding: .5em 1em 0 0;
    &.views-widget-filter-combine {
      width: 40%;
    }
    & input[type='submit'] {
      margin-top: 1.8em;
    }
  }
}

// discounts and flags
.pic-with-discount { position: relative; }
.views-field-field-discount,
.views-field-commerce-stock-1,
.views-field-field-discount-cash,
.views-field-field-flags,
.view .promo-flag,
.view .sold-flag,
.view .discount-flag {
  position: absolute;
  bottom: 11px;
  left: 4px;
  padding: .25em;
}
.views-field-commerce-stock-1,
.view .sold-flag {
  display: none; // hide by default to only show if sold
}

.view .secondary-flag {
  position: absolute;
  top: 5px;
  right: 4px;
  padding: .25em;
}

// target zero inventory in tradeknives and we inject the value of the commerce_stock
// field into the views row but it is numeric, which presents it's own problem, see
// https://stackoverflow.com/questions/21227702/is-there-a-workaround-to-make-css-classes-with-names-that-start-with-numbers-val
.\30 00 .views-field-field-discount,
.\30 00 .views-field-field-discount-cash,
.\30 00 .views-field-field-flags,
.\30 00 .promo-flag,
.\30 00 .secondary-flag,
.\30 00 .discount-flag {
  display: none;
}
.\30 00 .views-field-commerce-stock-1,
.\30 00 .sold-flag {
  display: block;
}

// hide discounts if temporary-flag is in views row
// hide temporary flag if sold
.temporary-flag .views-field-field-discount,
.temporary-flag .views-field-field-discount-cash,
.temporary-flag .discount-flag {
  display: none;
}

// set temp flag to upper/lower per client request
.temporary-flag .btn {
  text-transform: none;
}
