// Styles for panels and panes.
// -----------------------------------------------------------------------------

h1.pane-title {
  margin: 0;
  padding-bottom: 9.5px;
  border-bottom: 1px solid #eeeeee;
}

.panel-col-top,
.center-wrapper,
.panel-col-middle,
.panel-col-bottom {
  clear: both;
}

.panel-2col-bricks .panel-col-first,
.panel-2col-bricks .panel-col-last
{
  float: none;
  width: 100%;
}

@media (min-width: 992px) {
  .panel-2col-bricks .panel-col-first,
  .panel-2col-bricks .panel-col-last
  {
    float: left;
    width: 50%;
  }

}
