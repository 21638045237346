// Styles for Search (see also _view.scss for common styles)
// -----------------------------------------------------------------------------

.search-snippet-info { margin-bottom: 1em; }
.search-results .search-snippet-info { padding-left: 0; }

// search page search block
#edit-search-block-form--2 {
  width: 100%;
}
.form-item-search-block-form {
  width: 33%;
  margin-right: 1em;
}
#search-block-form {
  margin-bottom: 1em;
}