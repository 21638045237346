// Styles for Menus and Navs
// -----------------------------------------------------------------------------


@media screen and (min-width: 768px) {
  .navbar-default .navbar-nav > li > a {
    padding: 5px 15px;
    color: $brand-danger;
    border-radius: $nav-pills-border-radius;
    margin-right: 5px;
    &:hover {
      background-color: $gray-lighter;
    }
  }
  .navbar-default .navbar-nav > .active > a {
    background-color: $brand-danger;
    color: #fff;
    &:hover {
      background-color: $brand-danger;
      color: #fff;
    }
  }
  // mimic li.active for my account link
  //.navbar-nav a.active,
  .navbar-default .navbar-nav .menu-link-my-account a.active {
    color: #fff;
    background-color: $brand-danger;
  }
}

//@media (min-width: 992px) {
//  .navbar-nav > li > a {
//    font-size: .9em;
//  }
//}


// left nav makers menu
.pane-makers-menu {
  width: 180px;
  display: table-cell;
  background-color: #a8a8a8;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  & h2 {
    font-size: 1.3em;
    color:#fff;
    padding: 5px 10px;
    margin: 0;
    & a {
      color:#fff;
    }
  }
}
.makers-menu > li > a {
  font-size: 90%;
  padding: 2px 10px;
  line-height: 1.2;
  color: #fff;
  &:hover,
  &:focus,
  &.active {
    background-color: #919191;
  }
}

.pane-tradeknives {
  display: table-cell;
  padding-left: 20px;
  width: 85%;
}
@media screen and (max-width: 480px) {
  .pane-makers-menu {
    display: none;
  }
  .pane-tradeknives {
    display: inline;
    padding-left: 0;
  }
}
