// Styles for Cart
// -----------------------------------------------------------------------------
@media screen and (min-width: 768px) {
  form .checkout-buttons .btn {
    margin-right: 10px;
  }
  .commerce-credit-card-expiration,
  .form-item-commerce-payment-payment-details-credit-card-code {
    float: left;
    width: 50%;
  }
}
.commerce-credit-card-expiration .form-item {
  width: 45%;
  display: inline-block;
}
.commerce-month-year-divider {
  margin: 0 10px;
  display: inline-block;
}
