// Styles for Forms
// -----------------------------------------------------------------------------
textarea,
textarea.form-control,
input.form-control,
input[type=text],
input[type=password],
input[type=email],
input[type=number],
[type=text].form-control,
[type=password].form-control,
[type=email].form-control,
[type=tel].form-control,
fieldset.panel,
select, select.form-control {
  background: rgba(255,255,255,.5);
}