// Styles for Nodes
// -----------------------------------------------------------------------------
.node img,
.page-node img {
  margin-bottom: 1em;
}
.node-type-tradeknives .main-image,
.node-type-tradeknives .secondary-images {
  float: none;
  width: 100%;
  padding-right: 0;
}

.price-block {
  margin: 1em 0;
}

.best-cash-label,
.best-cash-price {
  font-weight: bold;
}

// hide price information on sold knives
.sold .discount,
.sold .add-to-cart-pane,
.for-sale .sold-flag,
.sold .hold-flag {
  display: none;
}
.sold-flag,
.hold-flag {
  font-weight: normal;
  text-transform: uppercase;
  padding: 6px 16px;
}

// set hold flag to upper/lower per client request
.hold-flag {
  text-transform: none;
}

@media (min-width: 992px) {
  .node-type-tradeknives .secondary-images .field-items .field-item:nth-child(3n) {
    margin-right: 0;
  }
  .node-type-tradeknives .secondary-images .field-item:nth-child(3n+1){
    clear: both;
  }
  .node-type-tradeknives .main-image {
    float: none;
    width: 100%;
    padding-right: 0;
  }
}

.node-type-tradeknives .field-name-commerce-price,
.node-type-tradeknives .field-name-field-product,
.node .links {
  clear: both;
}

.add-to-cart-pane {
  margin-right: 1em;
  display: inline-block;
}
.inquire-pane {
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .inquire-pane .btn { margin-top: -.25em; }
}

.player {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  margin-bottom: 1em;
}

.player iframe,
.player object,
.player embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.note {
  font-size: .9em;
  color: $gray;
}
.note.red {
  color: $brand-danger;
}

.field-type-paragraphs .field-item {
  margin-bottom: .5em;
}

.pane-notifications-ui-subscriptions ul li {
  list-style: none;
  margin-left: 0;
  & a { // style as button
    text-transform: uppercase;
    border-right: none;
    border-bottom: none;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    background-color: #51b64e;
    border-color: transparent;
    @include box-shadow(1px 1px 2px rgba(0,0,0,.3));
    @include transition(all 0.2s);
    &:hover,
    &:focus {
      background-color: #40943d;
      text-decoration: none;
    }
  }
}

.field-name-field-hashtags {
  font-size: .8em;
  color: #ccc;
}
