// Styles for Header (override radix/_structure)
// -----------------------------------------------------------------------------


.navbar-form input::placeholder {
  color: $brand-danger;
}

.site-name {
  float: none;
}
.site-slogan {
  font-family: $opensans;
  font-weight: 500;
  font-size: .7em;
  display: block;
  margin-top: .1em;
}
.navbar-brand {
  padding: 10px 15px;
}

@media screen and (min-width: 768px) {
  .navbar-header {
    float: none;
    position: relative;
  }
  .site-name {
    font-size: 1.5em;
    line-height: 1.2;
  }
  .site-slogan {
    position: absolute;
    right: 15px;
    top: 10px;
    font-weight: bold;
  }
  #main-menu {
    float: left;
  }
  .navbar-form {
    margin-top: 0;
  }
}

